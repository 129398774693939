import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchAuthSession } from '@aws-amplify/auth';
import {
    CircularProgress,
    Box,
    Typography,
    Tabs,
    Tab,
    AppBar,
    Button,
} from '@mui/material';
import DOMPurify from 'dompurify';
import Papa from 'papaparse';
import config from '../utils/Constants';

function AdmissionDetails() {
    const { id } = useParams();
    const [accessToken, setAccessToken] = useState('');
    const [detailsData, setDetailsData] = useState([]);
    const [groupedDetails, setGroupedDetails] = useState({});
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [error, setError] = useState(null);
    const apiUrl = config.getApiUrl();
    const baseUrl = config.getBaseUrl();
    const signInUrl = config.getSignInUrl();

    const [activeTab, setActiveTab] = useState(0);
    const [tabLabels, setTabLabels] = useState([]);

    // Fetch access token on component mount
    useEffect(() => {
        getAccessToken();
    }, []);

    // Fetch admission details when access token is available
    useEffect(() => {
        if (accessToken) {
            fetchAdmissionDetails();
        }
    }, [accessToken]);

    // Fetch Access Token
    const getAccessToken = async () => {
        try {
            const session = await fetchAuthSession();
            const token = session.tokens.accessToken.toString();
            setAccessToken(token);
        } catch (e) {
            console.error('Error fetching access token:', e);
            setError(e);
        }
    };

    // Fetch Admission Details
    const fetchAdmissionDetails = async () => {
        setLoadingDetails(true);
        setError(null);

        try {
            const response = await fetch(
                `${apiUrl}/api/catalog/admissions/${id}/details?format=html`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const jsonData = await response.json();

            // Group details by detail_type
            const grouped = jsonData.reduce((acc, detail) => {
                const { detail_type } = detail;
                if (!acc[detail_type]) {
                    acc[detail_type] = [];
                }
                acc[detail_type].push(detail);
                return acc;
            }, {});

            setDetailsData(jsonData);
            setGroupedDetails(grouped);

            // Extract tab labels from the grouped details
            setTabLabels(Object.keys(grouped));
        } catch (error) {
            console.error('Error fetching admission details:', error);
            setError(error);
        } finally {
            setLoadingDetails(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // Helper function to strip HTML tags and return plain text
    const extractTextFromHTML = (htmlString) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    // Export to CSV function using papaparse with plain text extraction
    const exportToCSV = () => {
        // Prepare the CSV data, including detail_type in each row, and stripping HTML from value
        const csvData = detailsData.map((detail) => ({
            DetailType: detail.detail_type,
            Key: detail.key,
            Value: extractTextFromHTML(detail.value), // Strip HTML tags
        }));

        const csv = Papa.unparse(csvData, {
            header: true,
        });

        // Create a Blob from the CSV data and download it
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', `admission_details_${id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Admission Details for ID: {id}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={exportToCSV}
                style={{ marginBottom: '20px' }}
                disabled={detailsData.length === 0} // Disable the button if there's no data
            >
                Export Details to CSV
            </Button>
            {loadingDetails ? (
                <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                    <Box ml={2}>Loading admission details...</Box>
                </Box>
            ) : error ? (
                <Box color="error.main" mt={2}>
                    Error: {error.message}
                </Box>
            ) : detailsData.length === 0 ? (
                <Box mt={2}>
                    <Typography>We're still working on this one... give us a few and check back soon!</Typography>
                </Box>
            ) : (
                <>
                    {/* Tabs */}
                    <AppBar position="static" color="default">
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabLabels.map((label, index) => (
                                <Tab key={index} label={label} />
                            ))}
                        </Tabs>
                    </AppBar>

                    {/* Tab Content */}
                    <Box p={2}>
                        {tabLabels.map((label, index) => (
                            <div
                                key={index}
                                role="tabpanel"
                                hidden={activeTab !== index}
                                id={`tabpanel-${index}`}
                                aria-labelledby={`tab-${index}`}
                            >
                                {activeTab === index && (
                                    <div>
                                        {groupedDetails[label].map((detail, idx) => (
                                            <div key={idx} style={{ marginBottom: '10px' }}>
                                                <Typography variant="subtitle1">{detail.key}</Typography>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(detail.value),
                                                    }}
                                                    style={{ border: '1px solid #ccc', padding: '10px' }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </Box>
                </>
            )}
        </div>
    );
}

export default AdmissionDetails;
