import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDetailedData, fetchGeneratorMethods, postGenNewField, postGenNewRow, generatePatient, fetchUserProfile } from '../redux/userSlice';  
import { CircularProgress, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Alert, Tabs, Tab, Button, Select, FormControl, InputLabel, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Divider, Accordion, AccordionSummary, AccordionDetails, Menu, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { saveAs } from 'file-saver'; 
import { track } from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import JSZip from 'jszip';
import axios from 'axios';
import * as XLSX from 'xlsx';


const TenantDetails = () => {
  const dispatch = useDispatch();
  const selectedTenant = useSelector((state) => state.user.selectedTenant);
  const tenantData = useSelector((state) => state.user.tenantData);
  const generatorMethodData = useSelector((state) => state.user.generatorMethodData); // Get generator method data from Redux
  const [loading, setLoading] = useState(false); // Loading state
  const [detailedDataMap, setDetailedDataMap] = useState({});
  const { detailedData, detailedLoading } = useSelector((state) => state.user);
  const [dataReadyMap, setDataReadyMap] = useState({});
  const [errorMap, setErrorMap] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDetailTab, setSelectedDetailTab] = useState(0);

  // const [newField, setNewField] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [generatorMethod, setGeneratorMethod] = useState('');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const admissionLimit = useSelector((state) => state.user.admissionLimit);
  const [upgradeMessage, setUpgradeMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [nextPageUrl, setNextPageUrl] = useState(null); 
  const [previousPageUrl, setPreviousPageUrl] = useState(null); 
  const userEmail = useSelector((state) => state.user.email || '');


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleRowVoteClick = () => {
    track("vote for creating specific row values");
    alert("Thank you for voting! We're on it!");
  };

  const handleTestPipelineVoteClick = () => {
    track("vote for integrating data into testing stack");
    alert("Thank you for voting! We're on it!");
  };

  const handleNextPage = async () => {
    setUpgradeMessage(''); // Reset upgrade message
    setSuccessMessage(''); // Reset success message
    if (nextPageUrl) {
      try {
        let newErrorMap = { ...errorMap };
        let newDataReadyMap = { ...dataReadyMap };
        let newDetailedDataMap = { ...detailedDataMap };
  
        for (const item of tenantData.items) {
          const result = await dispatch(fetchDetailedData(nextPageUrl)).unwrap(); // Fetch the next page data
          newDetailedDataMap[item.name] = result; // Update the detailedDataMap with new data
          console.log('newDetailedDataMap:', newDetailedDataMap);
          newDataReadyMap[item.name] = true;
          setNextPageUrl(result.next); // Update next page URL for future fetches
          setPreviousPageUrl(result.previous || null);
        }
  
        setErrorMap(newErrorMap);
        setDataReadyMap(newDataReadyMap);
        setDetailedDataMap(newDetailedDataMap);
  
      } catch (fetchError) {
        console.error("Error fetching next page data:", fetchError);
      }
    }
  };

  const handlePreviousPage = async () => {
    setUpgradeMessage(''); // Reset upgrade message
    setSuccessMessage(''); // Reset success message
  
    if (previousPageUrl) {
      try {  
        // Update detailed data and state maps
        let newErrorMap = { ...errorMap };
        let newDataReadyMap = { ...dataReadyMap };
        let newDetailedDataMap = { ...detailedDataMap };
  
        for (const item of tenantData.items) {
          const result = await dispatch(fetchDetailedData(previousPageUrl)).unwrap(); // Fetch the next page data
          newDetailedDataMap[item.name] = result; // Update the detailedDataMap with new data
          console.log('newDetailedDataMap:', newDetailedDataMap);
          newDataReadyMap[item.name] = true;
          setNextPageUrl(result.next); // Update next page URL for future fetches
          setPreviousPageUrl(result.previous || null);
        }
  
        setErrorMap(newErrorMap);
        setDataReadyMap(newDataReadyMap);
        setDetailedDataMap(newDetailedDataMap);
  
      } catch (fetchError) {
        console.log(previousPageUrl)
        console.error("Error fetching previous page data:", fetchError);
      }
    } else {
      console.warn("No previous page URL available.");
    }
  };
  



  const fieldTypeOptions = [
    { value: 'CharField', label: 'CharField' },
    { value: 'IntegerField', label: 'IntegerField' },
    { value: 'DateField', label: 'DateField' },
    { value: 'DateTimeField', label: 'DateTimeField' },
    { value: 'BooleanField', label: 'BooleanField' },
    { value: 'DecimalField', label: 'DecimalField' },
    { value: 'FloatField', label: 'FloatField' },
    { value: 'TextField', label: 'TextField' },
    { value: 'UUIDField', label: 'UUIDField' },
    // { value: 'ForeignKey', label: 'ForeignKey' },
    // { value: 'ManyToManyField', label: 'ManyToManyField' },
    // { value: 'OneToOneField', label: 'OneToOneField' },
    { value: 'BinaryField', label: 'BinaryField' },
    { value: 'DurationField', label: 'DurationField' },
    { value: 'EmailField', label: 'EmailField' },
  ];

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmitModal = async () => {
    const tableId = tenantData.items[selectedTab]?.id; // Assuming you have tableId in tenantData
  
    // Check for valid inputs
    if (!fieldName || !fieldTypeOptions) {
      console.error("Field Name or Field Type is invalid.");
      return;
    }
  
    if (tableId) {
        const newFieldData = {
          field_type: fieldType,
          name: fieldName,
          nullable: true, // or based on form input
          unique: false, // or based on form input
          indexed: false, // or based on form input
          generator_method_id: generatorMethod || 0,
          generator_options: {}, // Initialize as an empty object or based on input
          depends_on_ids: [] // Initialize as an empty array or based on input
        };
      
        dispatch(postGenNewField({ tableId, newFielddata: newFieldData }))
          .then(() => {
            console.log("New field created successfully");
            handleCloseModal(); // Close the modal on success
          })
          .catch((error) => {
            console.error("Failed to create new field:", error);
            // Here you can log the specific error details to debug further
            if (error?.message) {
              console.error("Error message:", error.message);
            }
            if (error?.data) {
              console.error("Error data:", error.data);
            }
          });
      } else {
        console.error("No valid table selected to add the field to");
      }
  };
  
  useEffect(() => {
    if (selectedTenant && tenantData && tenantData.items && tenantData.items.length > 0) {
      const fetchData = async (listDataUrl) => {
        try {
          let newErrorMap = {};
          let newDataReadyMap = {};
          let newDetailedDataMap = {};
  
          for (const item of tenantData.items) {
            const currentUrl = listDataUrl || item.list_data_url; // Use the provided URL or the item's URL
            if (currentUrl) {
              try {
                const result = await dispatch(fetchDetailedData(currentUrl)).unwrap();
                newDetailedDataMap[item.name] = result;
                newDataReadyMap[item.name] = true;
                setNextPageUrl(result.next); // Save the next page URL after initial fetch
              } catch (fetchError) {
                newErrorMap[item.name] = `Failed to fetch data for ${item.name}`;
                newDataReadyMap[item.name] = false;
              }
            } else {
              newErrorMap[item.name] = "List Data URL is missing for this item.";
              newDataReadyMap[item.name] = false;
            }
          }
  
          setErrorMap(newErrorMap);
          setDataReadyMap(newDataReadyMap);
          setDetailedDataMap(newDetailedDataMap);
  
        } catch (error) {
          console.error("Error fetching detailed data:", error);
        }
      };
  
      // Initial fetch
      fetchData();
  
    } else {
      setErrorMap({ global: "Please select a tenant" });
    }
  }, [tenantData, selectedTenant, dispatch]); // Trigger the effect only when tenantData or selectedTenant changes
  

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleDetailTabChange = (event, newDetailValue) => {
    setSelectedDetailTab(newDetailValue);
  };


  // const convertToCSV = (data) => {
  //   if (!data || data.length === 0) return '';

  //   const headers = Object.keys(data[0]); // Get headers from the first row
  //   const csvRows = [headers.join(',')]; // Create the header row

  //   // Add rows of data
  //   data.forEach((row) => {
  //     const values = headers.map(header => `"${row[header] || ''}"`); // Escape each value in quotes
  //     csvRows.push(values.join(','));
  //   });


  //   return csvRows.join('\n');
  // };

  const removeHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
  };
  
  const convertToCSV = (data) => {
    if (!data || data.length === 0) return '';
  
    // Flatten data to handle nested 'sections'
    const flattenData = data.map((row) => {
      const { sections, ...rest } = row; // Extract 'sections' and other fields
      if (Array.isArray(sections)) {
        sections.forEach((section) => {
          if (section.title && section.content) {
            rest[`section_${section.title}`] = removeHtmlTags(section.content); // Remove HTML tags
          }
        });
      }
      return rest;
    });
  
    // Get headers dynamically from the flattened data
    const headers = Array.from(
      new Set(flattenData.flatMap(row => Object.keys(row)))
    );
  
    const csvRows = [headers.join(',')]; // Create the header row
  
    // Add rows of data
    flattenData.forEach((row) => {
      const values = headers.map(header => `"${row[header] || ''}"`); // Escape each value in quotes
      csvRows.push(values.join(','));
    });
  
    return csvRows.join('\n');
  };

  const handleDownloadCSV = (tableName) => {
    const tableData = detailedDataMap[tableName]; // Get the specific table data for the selected table
  
    if (tableData && tableData.results && tableData.results.length > 0) {
      const csvData = convertToCSV(tableData.results); // Convert the results to CSV
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  
      saveAs(blob, `${tableName}_data.csv`);

    } else {
      console.error('No data available for CSV download.');
    }
  };

  const handleDownloadAllCSV = () => {
    tenantData.items.forEach((item) => {
      const tableName = item.name;
      const tableData = detailedDataMap[tableName];
  
      if (tableData && tableData.results && tableData.results.length > 0) {
        const csvData = convertToCSV(tableData.results); // Convert the results to CSV
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  
        const now = new Date();
        const timestamp = now.toISOString().replace(/[:.]/g, '-'); // Format the timestamp
  
        // Use the table name and timestamp for the file name
        saveAs(blob, `${tableName}_data_${timestamp}.csv`);
      } else {
        console.error(`No data available for table: ${tableName}`);
      }
    });
  };

  const handleDownloadAllExcel = () => {
    const workbook = XLSX.utils.book_new(); // Create a new workbook
  
    tenantData.items.forEach((item) => {
      const tableName = item.name;
      const tableData = detailedDataMap[tableName];
  
      if (tableData && tableData.results && tableData.results.length > 0) {
        // Convert the table data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(tableData.results);
        
        // Append the worksheet to the workbook with the table name
        XLSX.utils.book_append_sheet(workbook, worksheet, tableName);
      } else {
        console.warn(`No data available for table: ${tableName}`);
      }
    });
  
    // Create and download the Excel file
    const now = new Date();
    const timestamp = now.toISOString().replace(/[:.]/g, '-');
    XLSX.writeFile(workbook, `${selectedTenant.name}_tables_${timestamp}.xlsx`);
  };

  const handleGenNewField = () => {
    dispatch(fetchGeneratorMethods()); // Fetch generator methods when opening the modal
    handleOpenModal();
  };
  
  const handleGenNewRow = (tableName) => {
    setLoading(true);
    const lowerCaseTableName = tableName.toLowerCase();
  
    dispatch(postGenNewRow({ tableName: lowerCaseTableName }))
      .unwrap()
      .then(() => {
        alert("Success! New Row Generated");
        // Re-fetch detailed data for the current table after the new row is added
        const selectedTable = tenantData.items[selectedTab];
        if (selectedTable?.list_data_url) {
          dispatch(fetchDetailedData(selectedTable.list_data_url))
            .unwrap()
            .then((updatedData) => {
              setDetailedDataMap((prevData) => ({
                ...prevData,
                [tableName]: updatedData,
              }));
            })
            .catch((error) => {
              console.error('Failed to fetch updated data after adding new row:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Failed to generate new row:', error);
        alert("Error generating new row");
      });
    setLoading(false);
  };

// PAGINATION FUNCTION 
const handleNextPageAdmissions = async () => {
  if (loading || !tenantData.next) return; // Prevent requests when loading or no next page

  try {
    setLoading(true); // Start loading state

    console.log('Fetching next page:', tenantData.next);
    const response = await fetch(tenantData.next); // Use tenantData.next for the URL

    if (!response.ok) throw new Error('Failed to fetch the next page');

    const data = await response.json();

    // Update the state with the new data
    setDetailedDataMap((prevData) => ({
      ...prevData,
      items: [...prevData.items, ...data.results], // Append new items from results
      next: data.next, // Update the next page URL
      previous: data.previous, // Update the previous page URL
    }));
  } catch (error) {
    console.error('Error fetching next page:', error);
    setErrorMap(error.message || 'An error occurred while fetching data');
  } finally {
    setLoading(false); // Stop loading state
  }
};


// GENERATE SINGLE PATIENT FUNCTION

  const handleGenerateSinglePatient = async (tableName) => {
    setLoading(true);
    setErrorMap({});
    setUpgradeMessage(''); 
    setSuccessMessage('');

    try {
      const response = await dispatch(generatePatient()).unwrap();
      track('Generate Patient');

    const { id, firstname, lastname, age, gender, city, state } = response;
    setSuccessMessage(
      `Cynthetic patient generated successfully!
       ID: ${id}, Name: ${firstname} ${lastname}, 
       Age: ${age}, Gender: ${gender}, 
       Location: ${city}, ${state}.`
    );

      const selectedTable = tenantData.items[selectedTab];

      if (selectedTable?.list_data_url) {

        dispatch(fetchDetailedData(selectedTable.list_data_url))
            .unwrap()
            .then((updatedData) => {
              setDetailedDataMap((prevData) => ({
                ...prevData,
                [tableName]: updatedData,
              }));
            })
      }
    } catch (error) {

      const status = error?.status;
      const detailMessage = error?.data?.detail || error?.message;
  
      if (status === 400 && detailMessage?.includes('admission_limit')) {
        setUpgradeMessage(
          'You have reached the limit for generating new patients.'
        );
      } else {
        setErrorMap((prev) => ({
          ...prev,
          global: {
            message: detailMessage || 'An unexpected error occurred.',
          },
        }));
      }
    } finally {
      setLoading(false);
    }
  };
  


  // Create a new function to generate pdf
  // const handleDownloadPDF = async () => {
  //   setLoading(true);

  //   const pdf = new jsPDF('p', 'mm', 'a4');
  //   let yOffset = 20;
  //   const pageBottomMargin = 30; // Larger margin at the bottom of each page
  //   const pageTopMargin = 20;

  //   tenantData.items.forEach((item, index) => {
  //     const tableName = item.name;
  //     const tableData = detailedDataMap[tableName];

  //     if (!tableData || !tableData.results || tableData.results.length === 0) {
  //       console.warn(`No data available for table: ${tableName}`);
  //       return;
  //     }

  //     // Start each table section on a new page, after the first table
  //     if (index > 0) {
  //       pdf.addPage();
  //       yOffset = pageTopMargin; // Reset yOffset to top of new page with margin
  //     }

  //     // Table name styled as H2
  //     pdf.setFontSize(16);
  //     pdf.setFont('helvetica', 'bold');
  //     pdf.text(`${index + 1}. ${tableName}`, 10, yOffset);
  //     yOffset += 10; // Space after header

  //     pdf.setLineWidth(0.5);
  //     pdf.line(10, yOffset, 200, yOffset); // Horizontal line
  //     yOffset += 10;

  //     // Render table data as lists
  //     tableData.results.forEach((row, rowIndex) => {
  //       Object.entries(row).forEach(([key, value]) => {
  //         pdf.setFontSize(12);
  //         pdf.setFont('helvetica', 'normal');

  //         // Wrap text for longer content
  //         const text = `• ${key}: ${value || 'N/A'}`;
  //         const textLines = pdf.splitTextToSize(text, 180); // Wrap text to fit within 180mm
  //         pdf.text(textLines, 15, yOffset);
  //         yOffset += textLines.length * 6; // Adjust space based on wrapped lines
  //       });

  //       yOffset += 8; // Extra space between records

  //       // Add page if nearing the bottom margin
  //       if (yOffset > pdf.internal.pageSize.height - pageBottomMargin) {
  //         pdf.addPage();
  //         yOffset = pageTopMargin; // Reset yOffset to top of new page with margin
  //       }
  //     });

  //     yOffset += 10; // Space at the bottom of each table section
  //   });

  //   // Add current datetime to filename
  //   const now = new Date();
  //   const timestamp = now.toISOString().replace(/[:.]/g, '-'); // Format as "YYYY-MM-DDTHH-MM-SS"
  //   pdf.save(`${selectedTenant.name}_Export_${timestamp}.pdf`);

  //   setLoading(false);
  // };


const handleDownloadPDF = async () => {
  setLoading(true);

  try {
    const zip = new JSZip(); // Create a new ZIP file instance
    const pdfFolder = zip.folder('PDFs'); // Create a folder inside the ZIP

    const pdfPromises = [];

    tenantData.items.forEach((item) => {
      const tableName = item.name;
      const tableData = detailedDataMap[tableName];

      if (tableData && tableData.results && tableData.results.length > 0) {
        tableData.results.forEach((row) => {
          if (row.chart_pdf_url) {
            pdfPromises.push(
              axios.get(row.chart_pdf_url, { responseType: 'arraybuffer' }) // Fetch PDF as binary
                .then((response) => ({
                  data: response.data,
                  fileName: `${tableName}_row_${row.id}.pdf`,
                }))
            );
          }
        });
      } else {
        console.warn(`No data available for table: ${tableName}`);
      }
    });

    // Wait for all PDFs to be downloaded
    const pdfResponses = await Promise.all(pdfPromises);

    // Add each PDF to the ZIP file
    pdfResponses.forEach(({ data, fileName }) => {
      pdfFolder.file(fileName, data); // Add the binary PDF data to the ZIP folder
    });

    // Generate the ZIP file
    const zipContent = await zip.generateAsync({ type: 'blob' });

    // Trigger download of the ZIP file
    saveAs(zipContent, 'pdfs.zip');
  } catch (error) {
    console.error('Error downloading or creating ZIP file:', error);
  } finally {
    setLoading(false);
  }
};

  
  // If no tenant is selected, display a message
  if (!selectedTenant) {
    return (
      <Box>
        <Alert severity="warning">
          Please select a tenant to view admissions.
        </Alert>
      </Box>
    );
  }


  // When tenant is selected, show tenant data
  return (
    <Box>
      {/* Modal for generating a new field */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Generate New Field</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the options for the new field.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Field Name"
            fullWidth
            variant="outlined"
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
          />
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Field Type</InputLabel>
            <Select
              label="Field Type"
              value={fieldType}
              onChange={(e) => setFieldType(e.target.value)}
            >
              {fieldTypeOptions.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                    {type.label}
                </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Select Generator Method</InputLabel>
            <Select
              label="Select Generator Method"
              value={generatorMethod}
              onChange={(e) => setGeneratorMethod(e.target.value)}
            >
              {generatorMethodData?.map((method) => (
                <MenuItem key={method.id} value={method.generator.id}>
                  {method.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitModal} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {tenantData?.items && tenantData.items.length > 0 && (
        <>
          <Typography variant="h6">Workspace: {selectedTenant?.name}</Typography>
          <Button
            onClick={handleMenuClick}
            variant="outlined"
            color="secondary"
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            Download Dataset
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
            <MenuItem onClick={handleDownloadPDF}>{loading ? 'Downloading...' : 'Download All as PDF'}</MenuItem>
            <MenuItem onClick={handleDownloadAllCSV}>Download as CSV</MenuItem>
            {/* <MenuItem onClick={handleDownloadAllExcel}>Download All as Excel</MenuItem> */}
            <MenuItem onClick={handleTestPipelineVoteClick}>Connect to your Testing pipeline? Vote for Feature</MenuItem>
          </Menu>
          <Divider />
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tenant data tabs">
            {tenantData.items
              .filter(item => item.name === "Admissions") // Filter items by name
              .map((item, index) => (
                <Tab key={index} label={item.name} />
              ))}
            {/* <Button onClick={() => navigate(`/dashboard/upload-schema`)}>Create Dataset</Button> */}
          </Tabs>
          <Divider />
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {/* <Alert severity="warning" style={{ marginTop: '1rem', marginBottom: '1rem' }}>We're working on it...</Alert> */}
            </>
          )}
        </>
      )}

      
{tenantData?.items?.filter(item => item.name === "Admissions").map((item, index) => {
  if (selectedTab !== index) return null;
  
  const tableName = item.name;
  // if (tableName !== "Admissions") return null; // Only show the tab for "admissions"

  const tableData = detailedDataMap[tableName];
  const tableError = errorMap[tableName];
  const tableDataReady = dataReadyMap[tableName];

  return (
    <Box key={index} mt={4}>
      {/* <Typography variant="h6">Table: {tableName}</Typography> */}


      {tableError && (
        <Alert severity="error" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          {tableError}
        </Alert>
      )}
      {/* loading spinner */}
      {!tableError && !tableDataReady && (
        <>
          <CircularProgress />
        </>
      )}


{tableData && tableData.results.length > 0 ? (
        <>
          <Typography variant="h6">Total: {tableData.count}</Typography>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownloadCSV('tableName')} // Assuming you have a download function
            style={{ marginTop: '20px' }}
          >
            Download dataset as CSV
          </Button> */}

          {/* Render Summary Table */}
          <Table style={{ marginTop: '20px', border: '1px solid #ddd' }}>
            <TableHead>
              <TableRow>
                <TableCell><strong>Patient ID</strong></TableCell>
                <TableCell><strong>Patient Name</strong></TableCell>
                <TableCell><strong>Chief Complaint</strong></TableCell>
                <TableCell><strong>PDF Link</strong></TableCell>
                <TableCell><strong>Chart Link</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.results.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <TableRow
                    sx={{
                      background: rowIndex % 2 === 0 
                        ? 'linear-gradient(to right, rgb(90, 211, 217), rgb(150, 245, 250))'
                        : 'linear-gradient(to right, rgb(90, 211, 217), rgb(45, 195, 201))',
                      fontFamily: 'Roboto, sans-serif',
                      color: '#000',
                    }}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.patient_name}</TableCell>
                    <TableCell>{row.chief_complaint || 'No chief complaint available'}</TableCell>
                    <TableCell>
                      <a href={row.chart_pdf_url} target="_blank" rel="noopener noreferrer">
                        View PDF
                      </a>
                    </TableCell>
                    <TableCell>
                      <a href={row.chart_text_url} target="_blank" rel="noopener noreferrer">
                        View Chart
                      </a>
                    </TableCell>
                  </TableRow>

                  {/* Render Expandable Details */}
                  <TableRow
                    style={{
                      background: rowIndex % 2 === 0 
                        ? 'linear-gradient(to right, #d9f6fa, #bdeaf2)'
                        : 'linear-gradient(to right, #bdeaf2, #9edee7)',
                      color: '#333',
                      fontFamily: 'Roboto, sans-serif',
                      fontWeight: 300,
                      fontSize: '0.9rem',
                    }}
                  >
                    <TableCell colSpan={5}>
      <Accordion style={{ margin: '10px 0' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">
            <strong>Details</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Array.isArray(row.sections) && row.sections.length > 0 ? (
            <Box>
              {/* Tabs Header */}
              <Tabs
                value={selectedDetailTab}
                onChange={handleDetailTabChange}
                aria-label="Section Tabs"
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary" // Ensures the active tab has primary color
                indicatorColor="primary" // Adds an underline indicator for the active tab
              >
                {row.sections.map((section, secIndex) => (
                  <Tab key={secIndex} label={section.title} />
                ))}
              </Tabs>
              {/* Tab Content */}
                            <Box sx={{ p: 2 }}>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}
                              >
                                <InsertDriveFileIcon 
                                  style={{ marginRight: '5px', verticalAlign: 'middle' }} 
                                />
                                {row.sections[selectedDetailTab]?.title}
                              </Typography>
                              <Box
                                sx={{
                                  marginTop: '10px',
                                  backgroundColor: '#f9f9f9',
                                  padding: '10px',
                                  borderRadius: '5px',
                                }}
                                dangerouslySetInnerHTML={{ __html: row.sections[selectedDetailTab]?.content }}
                              />
                            </Box>
                          </Box>
                          ) : (
                            <Typography variant="body2">No additional details available.</Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {previousPageUrl && ( // Show the previous page button if available
            <Button
              variant="outlined"
              onClick={handlePreviousPage}
              disabled={detailedLoading} // Disable if loading
              style={{ marginTop: '20px', width: '45%' }}
            >
              {detailedLoading ? <CircularProgress size={24} /> : 'Previous page'}
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={handleNextPage}
            disabled={!detailedData.next || detailedLoading} // Disable if no next page or loading
            style={{
              marginTop: '20px',
              marginLeft: !previousPageUrl ? '0px' : '10%',
              width: '45%',
              backgroundColor: !detailedData.next || detailedLoading ? '#d3d3d3' : '#fff',
              color: !detailedData.next || detailedLoading ? '#a9a9a9' : '#00bfa5',
            }}
          >
            {detailedLoading ? <CircularProgress size={24} /> : 'Next page'}
          </Button>


          <Button 
            variant="contained" 
            onClick={() => handleGenerateSinglePatient()} 
            style={{ marginTop: '20px', width: '100%', backgroundColor: '#00bfa5', 
              color: '#ffffff'
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Generate Cynthetic Patient'}
          </Button>
          {successMessage && (
            <Alert severity="success" style={{ fontWeight: 'bold', marginTop: '1rem', marginBottom: '1rem' }}>
              {successMessage}
            </Alert>
          )}
          {upgradeMessage && (
            <Alert severity="warning" style={{ fontWeight: 'bold', marginTop: '1rem', marginBottom: '1rem' }}>
              {upgradeMessage}
              <Typography
                style={{ marginLeft: '5px', marginBottom: '1rem' }}
              >
                Click below to purchase additional credits. 1 credit = 1 patient + 1 admission.
              </Typography>
              <stripe-buy-button
                buy-button-id="buy_btn_1QXmbIAnYtC91dgVo0R4PJsx"
                publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                customer-email= {userEmail}
                style ={{marginRight:"10px"}}
              ></stripe-buy-button>
                <stripe-buy-button
                buy-button-id="buy_btn_1QXV0jAnYtC91dgVcRjtr5JB"
                publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                customer-email= {userEmail}
                style ={{marginRight:"10px"}}
              ></stripe-buy-button>
              <stripe-buy-button
                buy-button-id="buy_btn_1QXmcGAnYtC91dgV5C0kTRvb"
                publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                customer-email= {userEmail}
              ></stripe-buy-button>
              <Link 
                href="mailto:sales@bacclabs.io" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ display: "block" }}
              >
                Need more? Send us an email
              </Link>
              </Alert>
          )}
          <Button 
            variant="outlined" 
            onClick={handleRowVoteClick}
            style={{ marginTop: '20px', width: '100%' }}
          >
            Want to specify characteristics for the new row? Click here to vote for this feature!
          </Button>
        </>
      ) : (
        tableDataReady && 
        <>
          <Button 
            variant="contained" 
            onClick={() => handleGenerateSinglePatient()} 
            style={{ marginTop: '20px', width: '100%', backgroundColor: '#00bfa5', // Complementary teal
              color: '#ffffff'
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Generate Cynthetic Row'}
          </Button>
          {upgradeMessage && (
            <Alert severity="warning" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              {upgradeMessage}
              <Link
                href="https://buy.stripe.com/bIY8z329n6LzfAscMN"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: 'bold', marginLeft: '5px' }}
              >
                Click here to upgrade.
              </Link>
            </Alert>
          )}
        </>
      )}
    </Box>
  );
})}
{errorMap.upgrade && (
  <Alert severity="warning" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
    {errorMap.upgrade.message}{' '}
    {errorMap.upgrade.link && (
      <a href={errorMap.upgrade.link} target="_blank" rel="noopener noreferrer">
        Upgrade here
      </a>
    )}
  </Alert>
)}

{errorMap.global?.message && !loading && (
  <Alert severity="error" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
    {errorMap.global.message}
  </Alert>
)}
</Box>
);
}

export default TenantDetails;
