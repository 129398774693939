import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile, fetchTenantData, selectTenant } from '../redux/userSlice';
import { CircularProgress, FormControl, InputLabel, Select, MenuItem, Typography, Box, Alert } from '@mui/material';
import { SchemaUpload } from '../dashboard/SchemaUpload';

const Profile = () => {
  const dispatch = useDispatch();
  const { profile, loading, error, selectedTenant } = useSelector((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState('');
  const [showTenantDetails, setShowTenantDetails] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(false);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  const handleTenantSelection = async (event) => {
    const tenantName = event.target.value;
    const tenant = profile.tenants.find(tenant => tenant.name === tenantName);
    
    if (tenant) {
      dispatch(selectTenant(tenant)); 
      setSelectedTenantName(tenant.name);
      setShowTenantDetails(false);
      setTenantData(null);
      setIsFetchingData(true);

      try {
        const fetchedTenantData = await dispatch(fetchTenantData(tenant.tables_url)).unwrap();
        setTenantData(fetchedTenantData);
        setShowTenantDetails(true);
      } catch (error) {
        console.error("Error fetching tenant data:", error);
      } finally {
        setIsFetchingData(false);
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box>
      {error && (
        <>
        <Alert severity="error" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          {error}
        </Alert>
        <SchemaUpload />
        </>
      )}
      {profile && (
        <Box>
          {/* <Typography variant="h5" gutterBottom>Select a workspace to get started</Typography> */}

          {profile.tenants && profile.tenants.length > 0 && !selectedTenant ? (
            <FormControl fullWidth variant="outlined" style={{ marginTop: '5px', marginRight:"20px", marginBottom:"5px", backgroundColor:"#fff", minWidth: "300px",  borderRadius:"20px" }}>
              <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
              <Select
                labelId="tenant-select-label"
                value={selectedTenant ? selectedTenant.name : ''}  
                onChange={handleTenantSelection}                   
                label="Select Tenant"
                disabled={isFetchingData}
              >
                {profile.tenants.map((tenant, index) => (
                  <MenuItem key={index} value={tenant.name}>   
                    {tenant.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography></Typography>
          )}

          {isFetchingData && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}

        {/* {showTenantDetails && selectedTenant && tenantData && (
          <TenantDetails 
            selectedTenant={selectedTenant} 
            tenantData={tenantData}
          />
        )} */}
        {showTenantDetails && selectedTenant && tenantData && (
          <>
          {/* <Typography variant="h6" style={{marginTop:"20px", marginBottom:"20px"}}>Selected Workspace: {selectedTenantName}</Typography> */}
          <FormControl variant="outlined" style={{ marginTop: '5px', marginRight:"20px", marginBottom:"5px", backgroundColor:"#fff", minWidth: "300px", borderRadius:"20px" }}>
              <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
              <Select
                labelId="tenant-select-label"
                value={selectedTenant ? selectedTenant.name : ''}  
                onChange={handleTenantSelection}                   
                label="Select Tenant"
                disabled={isFetchingData}
              >
                {profile.tenants.map((tenant, index) => (
                  <MenuItem key={index} value={tenant.name}>   
                    {tenant.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        </Box>
      )}
    </Box>
  );
};

export default Profile;