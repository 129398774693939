import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, InputLabel, FormControl, Button, CircularProgress, Chip, Box } from '@mui/material';
import { fetchAuthSession } from '@aws-amplify/auth';
import config from '../utils/Constants';

function SearchableDropdown({ onCategorySelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);  // Store search results
  const [jsonData, setJsonData] = useState(null); // Store full jsonData
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLongTitle, setSelectedLongTitle] = useState(''); // Store the long_title for display in Chip
  const [accessToken, setAccessToken] = useState('');
  const [icdCode, setIcdCode] = useState('');
  const [icdVersion, setIcdVersion] = useState('');
  const apiUrl = config.getApiURl();

  const getAccessToken = async () => {
    try {
      const session = await fetchAuthSession(); // Fetch the authentication session
      const token = session.tokens.accessToken.toString();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  // Function to fetch search results
  const fetchResults = async () => {
    if (searchTerm.length < 3) {
      setResults([]);
      return;
    }

    if (!accessToken) {
      console.warn('Access token is not available.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/medchart/procedures/search?query=${encodeURIComponent(searchTerm)}&limit=10`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Connection': 'keep-alive',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }

      const jsonData = await response.json();
      setResults(jsonData.items.slice(0, 10) || []); // Limit results to top 10
      setJsonData(jsonData); // Store the full jsonData in state
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    fetchResults();
  };

  // Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchResults();
    }
  };

  const handleSelect = (event) => {
    const selectedIcdCode = event.target.value; // This is the icd_code from the dropdown
    setSelectedCategory(selectedIcdCode); // This updates the selected category

    if (jsonData && jsonData.items) {
      // Find the item in jsonData that matches the selected icd_code
      const selectedItem = jsonData.items.find(item => item.icd_code === selectedIcdCode);

      if (selectedItem) {
        setIcdCode(selectedItem.icd_code); // Set the icd_code
        setIcdVersion(selectedItem.icd_version); // Set the icd_version
        setSelectedLongTitle(selectedItem.long_title); // Set the long_title for display in the Chip

        if (onCategorySelect) {
          onCategorySelect(selectedItem); // Notify parent component with selected item
        }
      } else {
        console.warn('Selected value not found in jsonData');
      }
    }
  };

  // Use useEffect to log the updated selectedCategory
  useEffect(() => {
    console.log("Selected ICD Code:", selectedCategory);
    console.log("ICD CODE=", icdCode);
    console.log("ICD VERSION=", icdVersion);
  }, [selectedCategory, icdCode, icdVersion]);

  const handleClearSelection = () => {
    setSelectedCategory(''); // Clear selected category
    setSelectedLongTitle(''); // Clear long_title for Chip
    setSearchTerm(''); // Clear search term
    setResults([]); // Clear results
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <TextField
        label="Search for a procedure, diagnosis, or ICD-10 code"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleKeyDown} // Add event listener for Enter key
        autoComplete="off" // Prevent browser from interfering with the value
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        disabled={loading}
        style={{ marginTop: '16px' }}
      >
        Search
      </Button>

      {/* Show loading message or select component based on the loading state */}
      {loading && searchTerm.length >= 3 ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
          <Box ml={2}>Loading...</Box>
        </Box>
      ) : results.length > 0 ? (
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel>Select an ICD-10 description</InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleSelect}
            label="Select an ICD-10 description"
            displayEmpty
          >
            {results.map((item) => (
              <MenuItem key={item.icd_code} value={item.icd_code}>
                {item.long_title} {/* Show the long_title as the display text */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        searchTerm.length >= 3 && !loading && !error && (
          <Box mt={2}>No results found</Box>
        )
      )}

      {/* Display selected item as a chip */}
      {selectedLongTitle && (
        <Box mt={2}>
          <Chip
            label={selectedLongTitle} // Show the long_title in the chip
            onDelete={handleClearSelection}
            color="primary"
          />
        </Box>
      )}
    </div>
  );
}

export default SearchableDropdown;
