import React, { useState, useEffect } from 'react';
import { CircularProgress, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { fetchAuthSession } from '@aws-amplify/auth';

const PeopleList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState('');

  // Fetch access token
  const getAccessToken = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken.toString();
      setAccessToken(token);
    } catch (e) {
      console.error('Error fetching access token:', e);
      setError(e);
    }
  };

  // Fetch data
  const fetchData = async (token) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('https://cynthia-api.bacclabs.io/api/catalog/people', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const jsonData = await response.json();
      console.log('Fetched Data (Raw):', jsonData); // Log raw data from API

      if (jsonData.items && Array.isArray(jsonData.items) && jsonData.items.length > 0) {
        // Reverse the items array to show the most recently created patients first
        setData(jsonData.items.reverse());
      } else {
        console.warn('No valid data found in the response:', jsonData);
        setData([]); // Set empty array if no valid data
      }

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch access token and data on component mount
  useEffect(() => {
    const fetchDataOnLoad = async () => {
      await getAccessToken();
    };
    fetchDataOnLoad();
  }, []);

  // Fetch data when accessToken is available
  useEffect(() => {
    if (accessToken) {
      fetchData(accessToken);
    }
  }, [accessToken]);

  return (
    <div>
        <h3>People</h3>
      {loading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
          <Box ml={2}>Loading...</Box>
        </Box>
      )}
      {error && <p>Error: {error.message}</p>}
      {data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: '#5ad3d9' }}>
              <TableRow>
                <TableCell style={{  color: '#ffffff', fontWeight: 'bold' }}>ID</TableCell>
                <TableCell style={{  color: '#ffffff', fontWeight: 'bold' }}>First Name</TableCell>
                <TableCell style={{  color: '#ffffff', fontWeight: 'bold' }} >Last Name</TableCell>
                <TableCell style={{  color: '#ffffff', fontWeight: 'bold' }}>Age</TableCell>
                <TableCell style={{  color: '#ffffff', fontWeight: 'bold' }}>Gender</TableCell>
                <TableCell style={{  color: '#ffffff', fontWeight: 'bold' }}>Ethnicity</TableCell>
                <TableCell style={{  color: '#ffffff', fontWeight: 'bold' }}>Date of Birth</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.pk}</TableCell>
                  <TableCell>{item.first_name}</TableCell>
                  <TableCell>{item.last_name}</TableCell>
                  <TableCell>{item.age}</TableCell>
                  <TableCell>{item.gender}</TableCell>
                  <TableCell>{item.ethnicity}</TableCell>
                  <TableCell>{item.date_of_birth}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !loading && <p>No data available</p>
      )}
    </div>
  );
};

export default PeopleList;
