import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Alert, Link } from '@mui/material';
import { generatePatient } from '../redux/userSlice';
import { track } from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const GeneratePatients = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Hook for navigation
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [patientCount, setPatientCount] = useState(1);
    const [isFirstPatientGenerated, setIsFirstPatientGenerated] = useState(false);
    const [patientData, setPatientData] = useState([]); // Store generated patient data
    const [additionalPatientData, setAdditionalPatientData] = useState([]); // Store additional patients after the first

    const selectedTenant = useSelector((state) => state.user.selectedTenant);
    const tenantId = selectedTenant?.id;
    const admissionLimit = useSelector((state) => state.user.admissionLimit);
    const admissionCount = useSelector((state) => state.user.admissionCount);
    const userEmail = useSelector((state) => state.user.email || '');



    if (!selectedTenant) {
        return <Alert severity="warning">Please select a tenant to generate patients.</Alert>;
    }

   const handleGenerateSinglePatient = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await dispatch(generatePatient()).unwrap(); // Unwrap response for direct data access
            track('Generate Patient');
            setPatientData([response]); // Store first patient's data in the first table
            setIsFirstPatientGenerated(true);

        } catch (error) {
            const status = error?.status;
            const detailMessage = error?.data?.detail || error?.message;
            if (status === 400 && detailMessage?.includes('admission_limit')) {
                setError(
                  'You have reached the limit for generating new patients.'
                )
        } else {
            setError(error.message)
        } 
    }
        finally {
            setLoading(false);
        }
    };

    const handleGenerateMultiplePatients = async () => {
        setLoading(true);
        setError(null);
        try {
            const responses = [];
            for (let i = 0; i < patientCount; i++) {
                const response = await dispatch(generatePatient()).unwrap();
                responses.push(response);
            }
            setAdditionalPatientData(responses); // Store additional patients after the first in a separate table
            track('Generate Patients', { count: patientCount });
        } catch (error) {
            const status = error?.status;
            const detailMessage = error?.data?.detail || error?.message;

            if (status === 400 && detailMessage?.includes('admission_limit')) {
                setError(
                  'You have reached the limit for generating new patients.'
                );
            } else {
            setError(error.message);
        }}
        finally {
            setLoading(false);
        }
    };

    const handleSliderChange = (event, value) => {
        setPatientCount(value);
    };

    const handleViewAdmissions = () => {
        navigate('/dashboard'); // Navigate to the /dashboard route
    };

    return (
        <>
            <Typography variant="h5" gutterBottom>Generate Patients</Typography>

            {admissionCount <= 0 ? (
                <>
                    <Typography variant="body1" gutterBottom>
                        Click the button below to generate a single patient and start populating your data.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleGenerateSinglePatient}
                        disabled={loading}
                    >
                        {loading ? 'Generating...' : 'Generate Patient'}
                    </Button>
                    <TableContainer component={Paper} sx={{ mt: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Patient ID</TableCell>
                                    <TableCell>Full Name</TableCell>
                                    <TableCell>Age</TableCell>
                                    <TableCell>Gender</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Date of Birth</TableCell>
                                    <TableCell>Ethnicity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {patientData.map((patient, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{patient.id}</TableCell>
                                        <TableCell>{`${patient.firstname} ${patient.middlename} ${patient.lastname}`}</TableCell>
                                        <TableCell>{patient.age}</TableCell>
                                        <TableCell>{patient.gender}</TableCell>
                                        <TableCell>{`${patient.street_address}, ${patient.city}, ${patient.state} ${patient.zip}`}</TableCell>
                                        <TableCell>{patient.dob}</TableCell>
                                        <TableCell>{patient.ethnicity}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {isFirstPatientGenerated && (
                    <Alert severity="success" gutterBottom sx={{ mt: 2 }}>
                        {`Great! You generated your first patient. We automatically create admissions health data for every patient that you generate. 
                        Use the slider to generate up to 20 patients, for free. Generating more patients requires an upgrade.`}

                    </Alert>
                    )}
                </>
            ) : (
                <>
                    <Alert severity="info" sx={{ mt: 4 }}> 
                    Use the slider to generate up to 20 patients, for free. Generating more patients requires an upgrade. We automatically create admissions health data for every patient that you generate.</Alert>
                    
                    
                    <Slider
                        value={patientCount}
                        min={1}
                        max={100}
                        step={1}
                        marks
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        sx={{ mt: 2 }}
                    />

                    <Button
                        variant="contained"
                        onClick={handleGenerateMultiplePatients}
                        disabled={loading || patientCount > admissionLimit}
                        sx={{ mt: 2 }}
                    >
                        {loading ? 'Generating...' : `Generate ${patientCount} Patient(s)`}
                    </Button>

                    {admissionCount > admissionLimit && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            <stripe-buy-button
                            buy-button-id="buy_btn_1QXmbIAnYtC91dgVo0R4PJsx"
                            publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                            customer-email= {userEmail}
                            style ={{marginRight:"10px"}}
                        ></stripe-buy-button>
                            <stripe-buy-button
                            buy-button-id="buy_btn_1QXV0jAnYtC91dgVcRjtr5JB"
                            publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                            customer-email= {userEmail}
                            style ={{marginRight:"10px"}}
                        ></stripe-buy-button>
                        <stripe-buy-button
                            buy-button-id="buy_btn_1QXmcGAnYtC91dgV5C0kTRvb"
                            publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                            customer-email= {userEmail}
                        >
                        </stripe-buy-button>
                        </Typography>
                    )}

                    {/* Render second table for additional generated patients */}
                    {additionalPatientData.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ mt: 4 }}>Generated Patients</Typography>
                            <Alert severity="success" gutterBottom sx={{ mt: 2 }}>
                                {`Great! You generated some patients. We automatically create admissions health data for every patient that you generate. 
                                View the admissions for each patient below or create some more patients!`}

                            </Alert>
                            <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Patient ID</TableCell>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell>Age</TableCell>
                                            <TableCell>Gender</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Date of Birth</TableCell>
                                            <TableCell>Ethnicity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {additionalPatientData.map((patient, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{patient.id}</TableCell>
                                                <TableCell>{`${patient.firstname} ${patient.middlename} ${patient.lastname}`}</TableCell>
                                                <TableCell>{patient.age}</TableCell>
                                                <TableCell>{patient.gender}</TableCell>
                                                <TableCell>{`${patient.street_address}, ${patient.city}, ${patient.state} ${patient.zip}`}</TableCell>
                                                <TableCell>{patient.dob}</TableCell>
                                                <TableCell>{patient.ethnicity}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}

                    <Button
                        variant="outlined"
                        onClick={handleViewAdmissions}
                        sx={{ mt: 2 }}
                    >
                        View Admissions
                    </Button>
                </>
            )}

            {error && (
            <>
            <Alert severity="warning" style={{marginTop:'10px'}}>
                {error}
                <Typography
                // href="https://buy.stripe.com/00g7uZ4hvb1P2NG8wz"
                // href="https://buy.stripe.com/test_dR6g0efBkdrhdFK3cc" // Test link
                style={{ marginLeft: '5px', marginBottom: '1rem' }}
                >
                    Click below to purchase additional credits. 1 credit = 1 patient + 1 admission.
                </Typography>
                <stripe-buy-button
                buy-button-id="buy_btn_1QXmbIAnYtC91dgVo0R4PJsx"
                publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                customer-email= {userEmail}
                style ={{marginRight:"10px"}}
              ></stripe-buy-button>
                <stripe-buy-button
                buy-button-id="buy_btn_1QXV0jAnYtC91dgVcRjtr5JB"
                publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                customer-email= {userEmail}
                style ={{marginRight:"10px"}}
              ></stripe-buy-button>
              <stripe-buy-button
                buy-button-id="buy_btn_1QXmcGAnYtC91dgV5C0kTRvb"
                publishable-key="pk_live_51Ow9hmAnYtC91dgVseC5xftenSu6YvQCYCzWkukWwiZhzBJweJwA4UsRaLRB7IxDtPlHPvDlpISNnc7sIwe8rmd200RUH2J7NT"
                customer-email= {userEmail}
              >
              </stripe-buy-button>
              <Link 
                href="mailto:sales@bacclabs.io" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ display: "block" }}
                >
                Need more? Send us an email
                </Link>            
            </Alert>
            </>
            )
            }
        </>
    );
};

export default GeneratePatients;
