import * as React from 'react';
// import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Dashboard as DashboardIcon } from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Link } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { useSelector } from 'react-redux';
// import { selectTenant } from '../redux/userSlice';

const handleTrackEvent = (eventName) => {
  amplitude.track(eventName, {
    // You can add additional properties here if needed
  });
};

// Functional component to use useSelector
export const MainListItems = () => {
  // Move useSelector inside the component
  const selectedTenant = useSelector((state) => state.tenant?.selectedTenant);

  return (
    <List> {/* Ensure that ListItemButton components are wrapped inside List */}
      <ListItemButton component={Link} to="/dashboard" onClick={() => handleTrackEvent('Navigate to Dashboard')}>
        <ListItemIcon>
          <DashboardIcon style={{fill:"white" }} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton component={Link} to="/dashboard/create" onClick={() => handleTrackEvent('Navigate to Generate')}>
        <ListItemIcon>
          <AutoAwesomeIcon style={{fill:"white" }} />
        </ListItemIcon>
        <ListItemText primary="Generate Data" />
      </ListItemButton>
      <ListItemButton component={Link} to="/dashboard/generators" onClick={() => handleTrackEvent('Navigate to GeneratorMethods')}>
        <ListItemIcon>
          <AssignmentIcon style={{fill:"white" }} />
        </ListItemIcon>
        <ListItemText primary="Methods" />
      </ListItemButton>
      {/* <ListItemButton component={Link} to="/dashboard/upload-schema" onClick={() => handleTrackEvent('Navigate to UploadSchema')}>
        <ListItemIcon>
          <FileUploadIcon style={{fill:"white" }} />
        </ListItemIcon>
        <ListItemText primary="Create Dataset" />
      </ListItemButton> */}

      {/* Conditionally render tenant link if selectedTenant is available */}
      {selectedTenant && (
        <ListItemButton 
          component={Link} 
          to={`/dashboard/tenant/${selectedTenant.name}`} // Use tenant name dynamically
          onClick={() => handleTrackEvent('Navigate to Tenant Details')}
        >
          <ListItemIcon>
            <DashboardIcon style={{fill:"white" }} /> 
          </ListItemIcon>
          <ListItemText primary="Tenant Details" />
        </ListItemButton>
      )}
    </List>
  );
};


    {/* <ListItemButton component={Link} to="/dashboard/people" onClick={() => handleTrackEvent('Navigate to People List')} // Track event on click
    >
      <ListItemIcon>
        <PeopleIcon style={{fill:"white" }} />
      </ListItemIcon>
      <ListItemText primary="People" />
    </ListItemButton>
    <ListItemButton component={Link} to="/dashboard/admissions" onClick={() => handleTrackEvent('Navigate to Admissions')}>
      <ListItemIcon>
        <AssignmentIcon style={{fill:"white" }} />
      </ListItemIcon>
      <ListItemText primary="Admissions" />
    </ListItemButton> */}

export const secondaryListItems = (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton> */}
  </React.Fragment>
);
