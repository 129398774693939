import store from '../redux/store';

const env = process.env.NODE_ENV;

// If you want to force the local environment to use staging URLs
const useStagingForLocal = true;

const config = {
    API: {
        PRODUCTION: process.env.REACT_APP_API_URL_PROD,
        STAGING:  process.env.REACT_APP_API_URL_STAGING,
        LOCAL: useStagingForLocal ? process.env.REACT_APP_API_URL_STAGING : 'http://localhost:8000',
    },
    URLS: {
        PRODUCTION: process.env.REACT_APP_PRODUCTION_URL,
        STAGING: process.env.REACT_APP_STAGING_URL,
        LOCAL: useStagingForLocal ? process.env.REACT_APP_STAGING_URL : 'http://localhost:8000',
    },
    SIGNIN: {
        PRODUCTION: process.env.REACT_APP_SIGNIN_URL_PROD,
        STAGING: process.env.REACT_APP_SIGNIN_URL_STAGING,
        LOCAL: useStagingForLocal ? process.env.REACT_APP_SIGNIN_URL_STAGING : 'http://localhost:3001',
    },
    awsExports:
    {
        "aws_project_region": "us-east-1",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_4vv8UpaBv",
        "aws_user_pools_web_client_id": "5bbnhnsr29te3e4o1nb25rm9ll",
        "oauth": {
          "domain": "bacclabs.auth.us-east-1.amazoncognito.com",
          "scope": [
            "email",
            "profile",
            "openid"
          ],
          "redirectSignIn": useStagingForLocal ? process.env.REACT_APP_AWS_COGNITO_CALLBACK_URL : 'http://localhost:3001',
          "redirectSignOut": useStagingForLocal ? process.env.REACT_APP_AWS_COGNITO_SIGNOUT_URL : 'http://localhost:3001',
          "responseType": "code"
        }
      },

    // Retrieve the dynamically selected API URL
    getDynamicApiUrl() {
        const state = store.getState();
        const selectedTenantUrl = state.user.selectedTenantUrl;  // Get the selected tenant's API URL from Redux
        if (selectedTenantUrl) {
            return selectedTenantUrl;
        }
        return this.getApiUrl();  // Fallback to environment-based URL if no tenant is selected
    },

    // Original method to get API URL based on environment
    getApiUrl() {
        switch (env) {
            case 'production':
                return this.API.PRODUCTION;
            case 'staging':
                return this.API.STAGING;
            default:
                return this.API.LOCAL;
        }
    },

    getBaseUrl() {
        switch (env) {
            case 'production':
                return this.URLS.PRODUCTION;
            case 'staging':
                return this.URLS.STAGING;
            default:
                return this.URLS.LOCAL;
        }
    },

    getSignInUrl() {
        switch (env) {
            case 'production':
                return this.SIGNIN.PRODUCTION;
            case 'staging':
                return this.SIGNIN.STAGING;
            default:
                return this.SIGNIN.LOCAL;
        }
    }
};

export default config;
