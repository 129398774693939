import React, { useState, useEffect } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth';
import {
  CircularProgress,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableContainer,
  Paper,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import config from '../utils/Constants';

function AdmissionsList() {
  const [accessToken, setAccessToken] = useState('');
  const [admissionsData, setAdmissionsData] = useState([]);
  const [loadingAdmissions, setLoadingAdmissions] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const apiUrl = config.getApiUrl();

  // Fetch access token on component mount
  useEffect(() => {
    getAccessToken();
  }, []);

  // Fetch admissions data when access token is available
  useEffect(() => {
    if (accessToken) {
      fetchAdmissions();
    }
  }, [accessToken]);

  // Fetch Access Token
  const getAccessToken = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken.toString();
      setAccessToken(token);
    } catch (e) {
      console.error('Error fetching access token:', e);
      setError(e);
    }
  };

  // Fetch Admissions Data
  const fetchAdmissions = async () => {
    setLoadingAdmissions(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/catalog/admissions?limit=100`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const jsonData = await response.json();

      // Sort the admissions: 'created' status first, then by id (pk) in descending order
      const sortedAdmissions = [...jsonData.items].sort((a, b) => {
        if (a.status === 'created' && b.status !== 'created') return -1; // a comes first
        if (a.status !== 'created' && b.status === 'created') return 1;  // b comes first
        return b.pk - a.pk; // then sort by pk in descending order
      });

      setAdmissionsData(sortedAdmissions);
    } catch (error) {
      console.error('Error fetching admissions:', error);
      setError(error);
    } finally {
      setLoadingAdmissions(false);
    }
  };

  // Export to CSV function using papaparse
  const exportToCSV = () => {
    const createdAdmissions = admissionsData.filter((admission) => admission.status === 'created');

    const csv = Papa.unparse(createdAdmissions, {
      headers: true,
      columns: [
        'pk',
        'status',
        'first_name',
        'last_name',
        'date_of_birth',
        'gender',
        'ethnicity',
      ],
    });

    // Create a Blob from the CSV data and download it
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'admissions_created.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <h3>Admissions</h3>
      <Button variant="contained" style={{backgroundColor:"#5ad3d9"}} onClick={exportToCSV}>
        Export Created Admissions to CSV
      </Button>
      {loadingAdmissions ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
          <Box ml={2}>Loading admissions...</Box>
        </Box>
      ) : error ? (
        <Box color="error.main" mt={2}>
          Error: {error.message}
        </Box>
      ) : admissionsData.length > 0 ? (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table stickyHeader>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: '#5ad3d9', color: '#ffffff', fontWeight: 'bold' }}>ID</TableCell>
              <TableCell style={{ backgroundColor: '#5ad3d9', color: '#ffffff', fontWeight: 'bold' }}>Status</TableCell>
              <TableCell style={{ backgroundColor: '#5ad3d9', color: '#ffffff', fontWeight: 'bold' }}>First Name</TableCell>
              <TableCell style={{ backgroundColor: '#5ad3d9', color: '#ffffff', fontWeight: 'bold' }}>Last Name</TableCell>
              <TableCell style={{ backgroundColor: '#5ad3d9', color: '#ffffff', fontWeight: 'bold' }}>Date of Birth</TableCell>
              <TableCell style={{ backgroundColor: '#5ad3d9', color: '#ffffff', fontWeight: 'bold' }}>Gender</TableCell>
              <TableCell style={{ backgroundColor: '#5ad3d9', color: '#ffffff', fontWeight: 'bold' }}>Ethnicity</TableCell>
            </TableRow>
          </TableHead>
  
          {/* Table Body */}
          <TableBody>
            {admissionsData.map((admission) => (
              <TableRow
                key={admission.pk}
                hover
                onClick={() => navigate(`/dashboard/admissions/${admission.pk}`)}
                style={{ cursor: 'pointer', 
                  transition: 'background-color 0.3s ease',
                  backgroundColor: admission.status === 'created' ? '#e0f7fa' : 'inherit', // Conditional background color for "created"
                }}
                sx={{
                  '&:hover': { backgroundColor: '#f5f5f5' },
                  '&:nth-of-type(odd)': { backgroundColor: '#fafafa' }, // Striped rows
                }}
              >
                <TableCell>
                  <Typography variant="body2" style={{ fontWeight: '500' }}>{admission.pk}</Typography>
                </TableCell>
                <TableCell>{admission.status}</TableCell>
                <TableCell>{admission.first_name}</TableCell>
                <TableCell>{admission.last_name}</TableCell>
                <TableCell>{admission.date_of_birth}</TableCell>
                <TableCell>{admission.gender}</TableCell>
                <TableCell>{admission.ethnicity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      ) : (
        <div>No admissions found.</div>
      )}
    </div>
  );
}

export default AdmissionsList;
