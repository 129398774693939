import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGeneratorMethods } from '../redux/userSlice'; // Import the fetch action
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Alert } from '@mui/material';
import { track } from '@amplitude/analytics-browser';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const GeneratorMethods = () => {
  const dispatch = useDispatch();
  const handleVoteClick = () => {
    track("vote for creating generator methods");
    alert("Thank you for voting! We're on it!");
};
  // Get the generator methods data from the Redux store
  const generatorMethodData = useSelector((state) => state.user.generatorMethodData);
  const loading = useSelector((state) => state.user.generatorMethodLoading);
  const error = useSelector((state) => state.user.generatorMethodDataError);
  
  // Fetch generator methods when the component is mounted
  useEffect(() => {
    dispatch(fetchGeneratorMethods());
  }, [dispatch]);

  if (loading) {
    return <DotLottieReact  
    autoplay
    loop
    src={process.env.PUBLIC_URL + '/lottie-1.json'} 
      style={{minHeight:'250px', width: '250px', margin: '0 auto'}}
      />;
  }

  if (error) {
    return <Alert severity="warning">Please select a tenant to view generator methods. </Alert>;
  }

  if (!generatorMethodData || generatorMethodData.length === 0) {
    return <Typography>No generator methods available.</Typography>;
  }

  return (
    <>
    <Typography variant="h5" gutterBottom>Generator Methods</Typography>
    <Button 
            variant="outlined" 
            onClick={handleVoteClick}
            style ={{marginTop:"20px", marginBottom:"20px"}}
    >
        Would you like to create your own Generator Methods? click the button here to vote!
    </Button>    
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow style ={{backgroundColor:"rgb(90, 211, 217)", fontWeight:"bold"}}>
            <TableCell>Name</TableCell>
            <TableCell>Generator Method</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Docstring</TableCell>
            <TableCell>Parameters</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {generatorMethodData.map((method) => (
            <TableRow key={method.id}>
                <TableCell>{method.name}</TableCell>
              <TableCell>{method.generator.name}</TableCell>
              <TableCell>{method.type}</TableCell>
              <TableCell>{method.docstring || 'N/A'}</TableCell>
              <TableCell>{method.parameters || 'None'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleVoteClick}
            style ={{marginTop:"20px"}}
    >
        Would you like to create your own Generator Methods? click the button here to vote!
    </Button>    
    </>
  );
};

export default GeneratorMethods;
