import * as React from 'react';
import { useState } from 'react';
import { Route, Routes, Link, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CodeIcon from '@mui/icons-material/Code';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems, secondaryListItems } from './listItems';
import DataModels from './DataModels';
import Integrations from './Integrations';
import SyntheticDataGenerator from './SyntheticDataGenerator';
import ExportData from './ExportData';
import logowhite from '../assets/logo-white.png';
import GetCategories from '../actions/Categories';
import AdmissionsList from '../actions/AdmissionsList';
import AdmissionDetails from '../actions/AdmissionDetails';
import PeopleList from '../actions/PeopleList';
import config from '../utils/Constants';
import Profile from '../components/Profile';
import TenantDetails from '../components/TenantDetails';
import { SchemaUpload } from './SchemaUpload';
import GeneratorMethods from './GeneratorMethods';
import { signOut } from 'aws-amplify/auth';
import CombinedFlow from '../actions/CombinedFlow';
import GeneratePatients from './GeneratePatients';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://bacclabs.io/">
        Cynthia Data
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      backgroundColor: 'black',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Dashboard(user, selectedTenant, tenantData) {
  const [open, setOpen] = React.useState(true);
  const [state, setState] = useState(''); // Define state
  const [generatedData, setGeneratedData] = useState(null);
  const [isSignedOut, setIsSignedOut] = useState(false);
  const selectedTenantUrl = useSelector((state) => state.user.selectedTenantUrl);  // Get the selected tenant's API URL from Redux
  const admissionLimit = useSelector((state) => state.user.admissionLimit);



  const toggleDrawer = () => {
    setOpen(!open);
  };
  const logoStyle = {
    width: '140px',
    height: 'auto',
    cursor: 'pointer',
  };
  const apiUrl = config.getApiUrl();
  const clientApiUrl = config.getDynamicApiUrl(); // Use dynamic API URL


  async function handleSignOut() {
    try {
      await signOut();
      setIsSignedOut(true);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  }

  if (isSignedOut) {
    return <Navigate to="/" replace />; // Redirect after signing out
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex',  }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: 'black !important'
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <Profile style ={{marginTop:"20px", paddingTop:"20px"}} />            
            </Typography>
            {selectedTenantUrl && (
              <>
              <Button 
                style={{ color: '#5ad3d9', borderColor: '#5ad3d9', borderWidth: '1px', marginRight: "10px", marginLeft: "20px" }} 
                variant="outlined" 
                href={`${selectedTenantUrl}/api/v1/client/docs#/`}
                target='_blank'
              >
                <CodeIcon />
                API Docs
              </Button>
              </>
            )}
              <Button style={{color:'#fff', borderColor: '#fff', borderWidth: '1px', marginLeft:'10px'}} variant="outlined" onClick={handleSignOut}>
                Sign Out
              </Button>
            {/* <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} sx={{backgroundColor:"black !important", color:"#fff"}} >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              backgroundColor:"black !important", color:"#fff"
            }}
          >
            <img
              src={logowhite}
              style={logoStyle}
              alt="logo of cynthia"
            />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon style={{fill:"white" }} />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav"  sx={{backgroundColor:"black !important", color:"#fff"}}>
            <MainListItems />
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Routes>
                    <Route path="integrations" element={<Integrations />} />
                    <Route path="generator" element={<Profile />} />
                    <Route path="data-models" element={<DataModels />} />
                    <Route path="export" element={<ExportData data={generatedData} />} />
                    <Route path="categories" element={<GetCategories />} />
                    <Route path="admissions" element={<AdmissionsList />} />
                    <Route path="/admissions/:id" element={<AdmissionDetails />} />
                    <Route path="people" element={<PeopleList />} />
                    <Route path="dashboard/tenant/:tenantName" element={<TenantDetails />} />
                    <Route path="upload-schema" element={<SchemaUpload />} />
                    <Route path='generators' element={<GeneratorMethods />} />
                    <Route path="health" element={<CombinedFlow />} />
                    <Route path="create" element={<GeneratePatients />} />
                    <Route path="dashboard" element={<TenantDetails/>} />
                    <Route path="*" element={<TenantDetails />} /> {/* This should be last */}
                </Routes>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
