// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import config from '../utils/Constants';  // Import the config file to use the dynamic API URL
// import { generatePath } from 'react-router-dom';

// Async thunk to fetch user profile
export const fetchUserProfile = createAsyncThunk(
  'user/fetchUserProfile',
  async (_, thunkAPI) => {
    try {
      const apiUrl = config.getDynamicApiUrl(); // Use dynamic API URL

      // Fetch the authentication session to get the access token
      const session = await fetchAuthSession(); // Fetch the current auth session
      const idToken = session.tokens.idToken.toString(); // Get the ID token as string

      // Make the API request with Authorization header
      const response = await axios.get(`${apiUrl}/me/`, {
        headers: {
          Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
        },
      });

      return response.data; // Return the user profile data
    } catch (error) {
      console.error('Error fetching user profile:', error); // Log the error for debugging
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching user profile');
    }
  }
);

// Async thunk to fetch tenant data
export const fetchTenantData = createAsyncThunk(
  'user/fetchTenantData',
  async (tables_url, thunkAPI) => {
    try {
      const session = await fetchAuthSession();  // Fetch auth session
      const idToken = session.tokens.idToken.toString();

      // Make the API request to fetch tenant data using list_data_url
      const response = await axios.get(tables_url, {
        headers: { Authorization: `Bearer ${idToken}` },
      });

      return response.data;  // Return the tenant data
    } catch (error) {
      console.error("Error fetching tenant data:", error);
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching tenant data');
    }
  }
);

// Async thunk to fetch detailed data from list_data_url
export const fetchDetailedData = createAsyncThunk(
  'user/fetchDetailedData',
  async (list_data_url, thunkAPI) => {
    try {
      const session = await fetchAuthSession(); // Fetch the current auth session
      const idToken = session.tokens.idToken.toString(); // Get the ID token as string

      // Append `page_size=10` to the URL query parameters
      const url = new URL(list_data_url);
      url.searchParams.set('page_size', 10);

      const response = await axios.get(url.toString(), {
        headers: {
          Authorization: `Bearer ${idToken}`, // Include the ID token in the Authorization header
        },
      });

      return response.data; // Return the detailed data
    } catch (error) {
      console.error('Error fetching detailed data:', error); // Log the error for debugging
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching detailed data');
    }
  }
);
export const generatePatient = createAsyncThunk(
  'user/generatePatient',
  async (_, thunkAPI) => {
    try {
      const session = await fetchAuthSession(); // Fetch auth session
      const idToken = session.tokens.idToken.toString();

      const response = await axios.post(
        `${config.getDynamicApiUrl()}/api/v1/client/patients/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      return response.data; // Successfully return data
    } catch (error) {
      console.error('Error generating patient:', error);

      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue({
          status: error.response?.status || 500,
          data: error.response?.data || 'Unknown error',
          message: error.message || 'An error occurred',
        });
      }

      // Handle other errors (non-Axios)
      return thunkAPI.rejectWithValue({
        status: 500,
        message: 'Unexpected error occurred',
      });
    }
  }
);

export const fetchNextPageAdmissions = createAsyncThunk(
  'user/fetchNextPageAdmissions',
  async (nextUrl, thunkAPI) => {
    try {
      const session = await fetchAuthSession(); // Fetch auth session
      const idToken = session.tokens.idToken.toString();

      const response = await axios.get(nextUrl, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return response.data; // Return the paginated data
    } catch (error) {
      console.error('Error fetching next page:', error);

      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue({
          status: error.response?.status || 500,
          data: error.response?.data || 'Unknown error',
          message: error.message || 'An error occurred',
        });
      }

      // Handle other errors
      return thunkAPI.rejectWithValue({
        status: 500,
        message: 'Unexpected error occurred',
      });
    }
  }
);



// POST request to /catalog/schemas/upload endpoint
export const uploadSchema = createAsyncThunk(
  'user/uploadSchema',
  async (formData, thunkAPI) => {
    try {
      const session = await fetchAuthSession(); // Fetch auth session
      const idToken = session.tokens.idToken.toString();

      // Make the API request to upload schema
      const response = await axios.post(
        `${config.getDynamicApiUrl()}/api/v1/catalog/schemas/upload`, 
        formData, // Sending FormData
        {
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'multipart/form-data', // Set proper content type
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      console.error('Error uploading schema:', error); // Log the error for debugging
      return thunkAPI.rejectWithValue(error.response?.data || 'Error uploading schema');
    }
  }
);

// POST to create new table in tenant
export const createTable = createAsyncThunk(
  'user/createTable',
  async (tableData, { rejectWithValue }) => {
      try {
          const session = await fetchAuthSession(); // Fetch auth session
          const idToken = session.tokens.idToken.toString();

          // Correct the request using axios
          const response = await axios.post(`${config.getDynamicApiUrl()}/api/v1/catalog/tables`, tableData, {
              headers: {
                  'Authorization': `Bearer ${idToken}`,
                  'Content-Type': 'application/json', // Set proper content type
              },
          });

          // Check response status and return data
          return response.data; // Axios automatically parses the JSON response
      } catch (error) {
          // Handle error based on response or general error
          if (error.response) {
              return rejectWithValue(error.response.data.message || error.message);
          }
          return rejectWithValue(error.message);
      }
  }
);

// GET generator methods

export const fetchGeneratorMethods = createAsyncThunk(
  'user/fetchGeneratorMethods',
  async (_, thunkAPI) => {
    try {
      const session = await fetchAuthSession(); // Fetch auth session
      const idToken = session.tokens.idToken.toString();

      const response = await axios.get(`${config.getDynamicApiUrl()}/api/v1/catalog/generator_methods`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching generator methods:', error);
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching generator methods');
    }
  }
);

export const postGenNewField = createAsyncThunk(
  'user/postGenNewField',
  async ({ tableId, newFielddata }, thunkAPI) => {
    try {
      const session = await fetchAuthSession(); // Fetch auth session
      const idToken = session.tokens.idToken.toString();

      // Ensure the data structure is correct
      const payload = {
        field_type: newFielddata.field_type,
        name: newFielddata.name,
        nullable: newFielddata.nullable,
        unique: newFielddata.unique,
        indexed: newFielddata.indexed,
        generator_method_id: newFielddata.generator_method_id,
        generator_options: newFielddata.generator_options || {}, // Fallback to empty object if undefined
        depends_on_ids: newFielddata.depends_on_ids || [] // Fallback to empty array if undefined
      };

      const response = await axios.post(`${config.getDynamicApiUrl()}/api/v1/catalog/tables/${tableId}/fields`, payload, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error generating new field:', error);
      return thunkAPI.rejectWithValue(error.response?.data || 'Error generating new field');
    }
  }
);
export const postGenNewRow = createAsyncThunk(
  'user/postGenNewRow',
  async ({ tableName }, thunkAPI) => {
    try {
      const session = await fetchAuthSession(); // Fetch auth session
      const idToken = session.tokens.idToken.toString();

      const response = await axios.post(
        `${config.getDynamicApiUrl()}/api/v1/client/${tableName}`,
        {}, // No need to pass newRowData
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error generating new row:', error);
      return thunkAPI.rejectWithValue(error.response?.data || 'Error generating new row');
    }
  }
);


// Define the user slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: null,
    loading: false,
    email: '',
    error: null,
    admissionLimit: null,
    admissionCount: 0,
    tenantData: null,
    tenantLoading: false,
    tenantError: null,
    selectedTenant: null, // Add selectedTenant to the state
    detailedData: {
      results: [], // Store fetched items
      next: null, // Store the next page URL
      previous: null, // Store the previous page URL
    }, // To store detailed data from list_data_url
    detailedLoading: false, // To track loading state for detailed data
    detailedError: null, // To track error for detailed data
    uploading: false, // To track schema uploading state
    schemaData: null, // To store schema data
    schemaError: null, // To track schema error
    schemaSuccess: false, // To track schema upload success
    selectedTenantUrl: null, // Add selectedTenantUrl to the state
    generatorMethodData: [],
    generatorMethodLoading: false,
    generatorMethodDataError: null,
    tableLoading: false,
    tableError: null,
    tableSuccess: false,
  },
  reducers: {
    clearUserProfile: (state) => {
      state.profile = null;
      state.error = null;
    },
    clearTenantData: (state) => {
      state.tenantData = null;
      state.tenantError = null;
      state.detailedData = null; // Clear detailed data when tenant data is cleared
      state.tenantLoading = false;
      state.detailedLoading = false; // Clear loading state for detailed data
    },
    selectTenant: (state, action) => {
      state.selectedTenant = action.payload; // Update the selected tenant
      state.selectedTenantUrl = action.payload.api_base_url; // Update the selected tenant's API URL
    },
    resetSchemaSuccess: (state) => {
      state.schemaSuccess = false;
    },
    resetTableSuccess: (state) => {
      state.tableSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
        state.email = action.payload.email;
        // Assuming tenants have unique IDs and admission limits
        state.admissionLimit = action.payload.tenants?.reduce((acc, tenant) => {
            acc[tenant.id] = tenant.admissionLimit || 20; // Default to 20 if not provided
            return acc;
        }, {});
        state.admissionCount = action.payload.tenants?.reduce((acc, tenant) => {
          acc[tenant.id] = tenant.admissionCount || 0; // Default to 0 if not provided
          return acc;
      }, {});
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      })
      .addCase(fetchTenantData.pending, (state) => {
        state.tenantLoading = true;
        state.tenantError = null; // Clear previous error
      })
      .addCase(fetchTenantData.fulfilled, (state, action) => {
        state.tenantLoading = false;
        state.tenantData = action.payload; // Set the tenant data
      })
      .addCase(fetchTenantData.rejected, (state, action) => {
        state.tenantLoading = false;
        state.tenantError = action.payload; // Set the error message
      })
      .addCase(fetchDetailedData.pending, (state) => {
        state.detailedLoading = true;
        state.detailedError = null; // Clear previous error
      })
      .addCase(fetchDetailedData.fulfilled, (state, action) => {
        // Append results and update next/previous URLs
        const { results, next, previous } = action.payload;
        state.detailedLoading = false;
        state.detailedData.results = [...state.detailedData.results, ...results]; // Append new results
        state.detailedData.next = next; // Update next page URL
        state.detailedData.previous = previous; // Update previous page URL
      })
      .addCase(fetchDetailedData.rejected, (state, action) => {
        state.detailedLoading = false;
        state.detailedError = action.payload || 'Failed to fetch detailed data'; // Set error message
      })
      .addCase(uploadSchema.pending, (state) => {
        state.uploading = true;
        state.schemaError = null; // Clear previous error
        state.schemaSuccess = false; // Reset success on new upload

      })
      .addCase(uploadSchema.fulfilled, (state, action) => {
        state.uploading = false;
        state.schemaData = action.payload; // Set the schema data
        state.schemaSuccess = true; 
      })
      .addCase(uploadSchema.rejected, (state, action) => {
        state.uploading = false;
        state.schemaError = action.payload; // Set the error message
        state.schemaSuccess = false; // Ensure no success on failure

      })
      .addCase(fetchGeneratorMethods.pending, (state) => {
        state.generatorMethodLoading = true;
        state.generatorMethodDataError = null;
      })
      .addCase(fetchGeneratorMethods.fulfilled, (state, action) => {
        state.generatorMethodLoading = false;
        state.generatorMethodData = action.payload;
      })
      .addCase(fetchGeneratorMethods.rejected, (state, action) => {
        state.generatorMethodLoading = false;
        state.generatorMethodDataError = action.payload;
      })
      .addCase(createTable.pending, (state) => {
        state.tableLoading = true;
        state.tableError = null;
        state.tableSuccess = false;
      })
      .addCase(createTable.fulfilled, (state) => {
          state.tableLoading = false;
          state.tableSuccess = true;
      })
      .addCase(createTable.rejected, (state, action) => {
          state.tableLoading = false;
          state.tableError = action.payload || 'Failed to create dataset';
      })
      // Add cases for fetchNextPageAdmissions
      .addCase(fetchNextPageAdmissions.pending, (state) => {
        state.tenantLoading = true; // Track loading state
        state.tenantError = null; // Clear any previous error
      })
      .addCase(fetchNextPageAdmissions.fulfilled, (state, action) => {
        const newItems = action.payload.results; // New data from API
        state.tenantData = {
          ...state.tenantData,
          results: [...state.tenantData.results, ...newItems], // Append new results
          next: action.payload.next, // Update the next page URL
          previous: action.payload.previous, // Update the previous page URL
        };
        state.tenantLoading = false; // Stop loading state
      })
      .addCase(fetchNextPageAdmissions.rejected, (state, action) => {
        state.tenantLoading = false; // Stop loading state
        state.tenantError = action.payload?.message || 'Failed to fetch next page'; // Set error
      });
  },
});

// Export actions and reducer
export const { clearUserProfile, clearTenantData, selectTenant, resetSchemaSuccess, generatorMethodData, resetTableSuccess } = userSlice.actions;
export default userSlice.reducer;
