import React, { useState, useEffect } from 'react';
import { Slider, Input, Button, CircularProgress, Box, TextField, Select, MenuItem, InputLabel, FormControl, Chip, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { fetchAuthSession } from '@aws-amplify/auth';
import * as amplitude from '@amplitude/analytics-browser';
import config from '../utils/Constants';

const MAX_FREE_SELECTION = 10;

function CombinedFlow() {
  const [value, setValue] = useState(1); // For number of people to generate
  const [peopleData, setPeopleData] = useState([]);
  const [peopleIds, setPeopleIds] = useState([]);
  const [loadingPeople, setLoadingPeople] = useState(false); // Loading state for people generation
  const [loadingCategories, setLoadingCategories] = useState(false); // Loading state for category search
  const [loadingFormulation, setLoadingFormulation] = useState(false); // Loading state for formulation
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [hasUpgraded, setHasUpgraded] = useState(false);
  const apiUrl = config.getApiUrl();

  // Step 2: Search related states
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLongTitle, setSelectedLongTitle] = useState('');
  const [icdCode, setIcdCode] = useState('');
  const [icdVersion, setIcdVersion] = useState('');

  // Step 3: Formulation data
  const [formulatedData, setFormulatedData] = useState([]);

  // Track completion of each step
  const [peopleGenerated, setPeopleGenerated] = useState(false); // Marks people generation as completed
  const [categorySelected, setCategorySelected] = useState(false); // Marks category selection as completed

  // Fetch Access Token
  const getAccessToken = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken.toString();
      setAccessToken(token);
    } catch (e) {
      console.error('Error fetching access token:', e);
      setError(e);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  // Step 1: Generate People
  const fetchPeople = async () => {
    if (!accessToken) return;

    setLoadingPeople(true); // Start loading
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/catalog/people`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ count: value }),
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const jsonData = await response.json();
      setPeopleData(jsonData);
      const pks = jsonData.map(item => item.pk).filter(Boolean);
      setPeopleIds(pks);
      setPeopleGenerated(true); // Mark step 1 as completed
      console.log('People IDs:', pks);
    } catch (error) {
      setError(error);
    } finally {
      setLoadingPeople(false); // End loading
    }
  };

  const handleGeneratePeople = async () => {
    if (value > MAX_FREE_SELECTION && !hasUpgraded) {
      alert('Upgrade required to generate more than 10 people.');
      amplitude.track('Upgrade Required');
    } else {
      fetchPeople();
      amplitude.track('Generate People Click');
    }
  };

  const handleUpgradeClick = () => {
    window.location.href = 'https://buy.stripe.com/bIY02x4hv1rfcogfYY'; // Replace with your Stripe link
    setHasUpgraded(true);
  };

  // Step 2: Search ICD-10 Code
  const fetchResults = async () => {
    if (searchTerm.length < 3) {
      setResults([]);
      return;
    }

    if (!accessToken) {
      console.warn('Access token is not available.');
      return;
    }

    setLoadingCategories(true); // Start loading
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/medchart/procedures/search?query=${encodeURIComponent(searchTerm)}&limit=10`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch search results');

      const jsonData = await response.json();
      setResults(jsonData.items.slice(0, 10) || []);
      amplitude.track('Search ICD-10 Code', { searchTerm });
    } catch (error) {
      setError(error);
    } finally {
      setLoadingCategories(false); // End loading
    }
  };

  const handleSelectCategory = (event) => {
    const selectedIcdCode = event.target.value;
    setSelectedCategory(selectedIcdCode);

    const selectedItem = results.find(item => item.icd_code === selectedIcdCode);
    if (selectedItem) {
      setIcdCode(selectedItem.icd_code);
      setIcdVersion(selectedItem.icd_version);
      setSelectedLongTitle(selectedItem.long_title);
      setCategorySelected(true); // Mark step 2 as completed
      amplitude.track('Category Selected', { icdCode: selectedIcdCode });
    }
  };

  // Step 3: Fetch Formulated Data
  const fetchFormulatedData = async () => {
    if (!accessToken || !peopleIds.length || !selectedCategory) return;

    setLoadingFormulation(true); // Start loading
    setError(null);

    try {
      const responses = await Promise.all(
        peopleIds.map(async (pk) => {
          const response = await fetch(`${apiUrl}/api/catalog/admissions`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              "people_id": pk,
              "search_criteria": {
                "procedure_icd_codes": [
                  {
                    "icd_code": icdCode,
                    "icd_version": icdVersion
                  }
                ]
              }
            }),
          });

          if (!response.ok) throw new Error(`Error fetching admission data: ${response.status}`);

          return response.json();
        })
      );

      setFormulatedData(responses);
      amplitude.track('Generate Admissions', { icdCode });
    } catch (error) {
      setError(error);
    } finally {
      setLoadingFormulation(false); // End loading
    }
  };

  return (
    <div>
      {/* Step 1: Generate People */}
      <div>
        <h3 style={{fontFamily:'PT Sans, sans-serif'}}>Step 1: Generate People</h3>
        <Input
          value={value}
          size="small"
          onChange={(e) => setValue(e.target.value)}
          inputProps={{ min: 1, max: 100, type: 'number' }}
        />
        <Slider
          value={typeof value === 'number' ? value : 0}
          onChange={(e, val) => setValue(val)}
          min={1}
          max={100}
        />
        <Button variant="contained" color="primary" onClick={handleGeneratePeople} disabled={loadingPeople}>
          Generate People
        </Button>
        {value > MAX_FREE_SELECTION && !hasUpgraded && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleUpgradeClick}
            style={{ marginLeft: '20px' }}
          >
            Upgrade to generate more than {MAX_FREE_SELECTION} people
          </Button>
        )}
        {loadingPeople && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
            <Box ml={2}>Generating people...</Box>
          </Box>
        )}
      </div>

      {/* Display Generated People */}
      {peopleData.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Ethnicity</TableCell>
              <TableCell>DOB</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {peopleData.map((person, index) => (
              <TableRow key={index}>
                <TableCell>{person.pk}</TableCell>
                <TableCell>{person.first_name}</TableCell>
                <TableCell>{person.last_name}</TableCell>
                <TableCell>{person.age}</TableCell>
                <TableCell>{person.gender}</TableCell>
                <TableCell>{person.ethnicity}</TableCell>
                <TableCell>{person.date_of_birth}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

<div>
  <h3 style={{fontFamily:'PT Sans, sans-serif'}}>Step 2: Search ICD-10 Code</h3>
  <TextField style={{ width: '100%',
      opacity: (!peopleGenerated || loadingPeople) ? 0.6 : 1, }}
    label="Search for a procedure, or ICD-10 Code"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        fetchResults(); // Trigger the search when Enter is pressed
      }
    }}
    // disabled={!peopleGenerated || loadingPeople} // Disable if people are not generated
  />
  <Button
    onClick={fetchResults}
    // disabled={!peopleGenerated || loadingCategories}
    style={{
      marginTop: "10px",
      backgroundColor: "#1976d2",
      color: "#fff",
      width: "100%",
    }}
  >
    Search
  </Button>
  
  {loadingCategories && (
    <Box display="flex" justifyContent="center" mt={2}>
      <CircularProgress />
      <Box ml={2}>Searching...</Box>
    </Box>
  )}
  
  {results.length > 0 && (
    <FormControl fullWidth style={{ marginTop: '20px' }}>
      <InputLabel style={{ marginBottom: '40px' }}>Select ICD-10 Code</InputLabel>
      <Select value={selectedCategory} onChange={handleSelectCategory}>
        {results.map((item) => (
          <MenuItem key={item.icd_code} value={item.icd_code}>
            {item.long_title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )}
  
  {/* Display the Chip with the ability to remove it */}
  {selectedLongTitle && (
    <Chip
      label={selectedLongTitle}
      onDelete={() => {
        setSelectedCategory(''); // Clear selected category
        setSelectedLongTitle(''); // Clear the long title on chip
        setSearchTerm(''); // Optionally, reset the search term
        setResults([]); // Clear the search results
      }}
      color="primary"
      style={{ marginTop: '20px' }}
    />
  )}
</div>



      {/* Step 3: Fetch Formulated Data */}
      <div>
        <h3 style={{fontFamily:'PT Sans, sans-serif'}}>Step 3: Generate Admissions Data</h3>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchFormulatedData}
          // disabled={!categorySelected || loadingFormulation} // Disable if category not selected or loading
        >
          Create Admissions
        </Button>
        {loadingFormulation && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
            <Box ml={2}>creating admissions data...</Box>
          </Box>
        )}
      </div>

      {/* Display Formulated Data with Status */}
      {formulatedData.length > 0 && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formulatedData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{peopleIds[index]}</TableCell>
                  <TableCell>{data.status || 'No status available'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button 
            onClick={() => window.location.href = '/dashboard/admissions'} 
            style={{ marginTop: '20px', backgroundColor: '#1976d2', color: '#fff', width: '100%' }}
          >
            View Admissions
          </Button>
        </>
      )}
    </div>
  );
}

export default CombinedFlow;
