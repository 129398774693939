import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    uploadSchema,
    resetSchemaSuccess,
    createTable,
    resetTableSuccess,
    fetchGeneratorMethods,
} from '../redux/userSlice';
import {
    Button,
    CircularProgress,
    Typography,
    Box,
    Alert,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    List,
    ListItem,
    ListItemText,
    IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const SchemaUpload = () => {
    const dispatch = useDispatch();
    const {
        schemaLoading,
        schemaError,
        schemaSuccess,
        tableLoading,
        tableError,
        tableSuccess,
        selectedTenantUrl,
        generatorMethodData,
    } = useSelector((state) => state.user);

    const [file, setFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [tableData, setTableData] = useState({ name: '', fields: [] });
    const [fieldName, setFieldName] = useState('');
    const [fieldType, setFieldType] = useState('');
    const [fieldGeneratorMethod, setFieldGeneratorMethod] = useState('');
    const [fieldGeneratorMethodName, setFieldGeneratorMethodName] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [error, setError] = useState('');

    const fieldTypeOptions = [
        { value: 'CharField', label: 'CharField' },
        { value: 'IntegerField', label: 'IntegerField' },
        { value: 'DateField', label: 'DateField' },
        { value: 'DateTimeField', label: 'DateTimeField' },
        { value: 'BooleanField', label: 'BooleanField' },
        { value: 'DecimalField', label: 'DecimalField' },
        { value: 'FloatField', label: 'FloatField' },
        { value: 'TextField', label: 'TextField' },
        { value: 'UUIDField', label: 'UUIDField' },
        { value: 'BinaryField', label: 'BinaryField' },
        { value: 'DurationField', label: 'DurationField' },
        { value: 'EmailField', label: 'EmailField' },
    ];

    // Fetch generator methods when the component mounts
   // Fetch generator methods when the component mounts and when selectedTenantUrl changes
    useEffect(() => {
        if (selectedTenantUrl) {
            dispatch(fetchGeneratorMethods());
        }
    }, [dispatch, selectedTenantUrl]);


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            setSelectedFileName(selectedFile.name);
        }
    };

    const handleUpload = () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            dispatch(uploadSchema(formData));
        }
    };

    useEffect(() => {
        if (schemaSuccess) {
            const timer = setTimeout(() => {
                dispatch(resetSchemaSuccess());
                setSelectedFileName('');
                setFile(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [schemaSuccess, dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTableData({ ...tableData, [name]: value });
    };

    const addOrUpdateField = () => {
        if (!fieldName || !fieldType || !fieldGeneratorMethod) {
            setError('All field details (name, type, and generator method) are required.');
            return;
        }

        const newField = {
            name: fieldName,
            type: fieldType,
            generatorMethod: fieldGeneratorMethod,
            generatorMethodName: fieldGeneratorMethodName,
        };

        setTableData((prevData) => ({
            ...prevData,
            fields: editIndex !== null
                ? prevData.fields.map((field, index) => (index === editIndex ? newField : field))
                : [...prevData.fields, newField],
        }));

        resetFieldInputs();
    };

    const handleEditField = (index) => {
        const fieldToEdit = tableData.fields[index];
        setFieldName(fieldToEdit.name);
        setFieldType(fieldToEdit.type);
        setFieldGeneratorMethod(fieldToEdit.generatorMethod);
        setFieldGeneratorMethodName(fieldToEdit.generatorMethodName);
        setEditIndex(index);
    };

    const handleDeleteField = (index) => {
        setTableData((prevData) => ({
            ...prevData,
            fields: prevData.fields.filter((_, i) => i !== index),
        }));
    };

    const resetFieldInputs = () => {
        setFieldName('');
        setFieldType('');
        setFieldGeneratorMethod('');
        setFieldGeneratorMethodName('');
        setEditIndex(null);
        setError('');
    };

    const handleFormSubmit = () => {
        const tablePayload = {
            name: tableData.name,
            base_class: 'client_api.models.BaseModel',
            meta_options: {},
            fields: tableData.fields.map((field) => ({
                name: field.name,
                type: field.type,
                generator_method: field.generatorMethod,
            })),
        };
        dispatch(createTable(tablePayload));
    };

    useEffect(() => {
        if (tableSuccess) {
            const timer = setTimeout(() => {
                dispatch(resetTableSuccess());
                setTableData({ name: '', fields: [] });
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [tableSuccess, dispatch]);

    // Check for selectedTenantUrl before rendering the main content
    if (!selectedTenantUrl) {
        return (
            <Alert severity="warning">Please select a tenant before creating a table or uploading a schema.</Alert>
        );
    }

    return (
        <>
            <Typography variant="h4">Create a new dataset</Typography>
            <Divider style={{ marginBottom: '20px' }} />

            <Box>
                <Typography>Upload a .sql schema file to generate a new dataset automagically</Typography>

                <input
                    accept=".sql"
                    id="schema-file"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                <label htmlFor="schema-file">
                    <Button variant="contained" component="span">
                        Choose File
                    </Button>
                </label>

                {selectedFileName && <Typography variant="body2">Selected file: {selectedFileName}</Typography>}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    disabled={schemaLoading || !file}
                    style={{ marginLeft: '10px' }}
                >
                    Upload Schema
                </Button>

                {schemaLoading && <CircularProgress />}
                {schemaError && <Alert severity="error">{schemaError}</Alert>}
                {schemaSuccess && <Alert severity="success">File "{selectedFileName}" uploaded successfully!</Alert>}
            </Box>
            <Box mt={4}>
            <Divider style={{ marginBottom: '20px' }} />
                <Typography variant="h6">OR</Typography>

            <Typography variant="h6" style={{ marginTop: '10px' }}>Create a dataset manually</Typography>
                <TextField
                    label="Dataset Name"
                    name="name"
                    value={tableData.name}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />

                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }}>
                    <TextField
                        label="Field Name"
                        value={fieldName}
                        onChange={(e) => setFieldName(e.target.value)}
                        fullWidth
                    />

                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Field Type</InputLabel>
                        <Select
                            value={fieldType}
                            onChange={(e) => setFieldType(e.target.value)}
                        >
                            {fieldTypeOptions.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Generator Method</InputLabel>
                        <Select
                            value={fieldGeneratorMethod}
                            onChange={(e) => {
                                const selectedMethod = generatorMethodData.find(method => method.id === e.target.value);
                                setFieldGeneratorMethod(selectedMethod.id);
                                setFieldGeneratorMethodName(selectedMethod.name);
                            }}
                        >
                            {generatorMethodData?.map((method) => (
                                <MenuItem key={method.id} value={method.id}>
                                    {method.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                {error && <Alert severity="error" style={{ marginTop: '10px' }}>{error}</Alert>}

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={addOrUpdateField}
                    style={{ marginTop: '10px' }}
                >
                    {editIndex !== null ? 'Update Field' : 'Add Field'}
                </Button>

                {tableData.fields.length > 0 && (
                    <List>
                        {tableData.fields.map((field, index) => (
                            <ListItem key={index} secondaryAction={
                                <>
                                    <IconButton edge="end" onClick={() => handleEditField(index)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" onClick={() => handleDeleteField(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            }>
                                <ListItemText 
                                    primary={field.name}
                                    secondary={`Type: ${field.type}, Generator: ${field.generatorMethodName}`} 
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={handleFormSubmit}
                disabled={tableLoading || !tableData.name || tableData.fields.length === 0} // Check for table name
                style={{ marginTop: '20px' }}
            >
                Create Dataset
            </Button>

            {tableLoading && <CircularProgress />}
            {tableError && <Alert severity="error">{tableError}</Alert>}
            {tableSuccess && <Alert severity="success">Table created successfully!</Alert>}
        </>
    );
};

export { SchemaUpload };
